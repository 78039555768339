@font-face {
    font-family: 'OpenSansRegular';
    src: local('OpenSansRegular'), url('../../../assets/fonts/Open_Sans/static/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansSemibold';
    src: local('OpenSansSemibold'), url('../../../assets/fonts/Open_Sans/static/OpenSans-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.select select {
    background-image: url('../../../assets/icons/arrow_down.png');
    background-repeat: no-repeat;
    background-position: 98% center;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-size: 15px;
    outline: none;
    font-family: 'OpenSansSemibold';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.select select::-ms-expand {
    display: none;
}

.options_select_search {
    clip-path: border-box;
}

.options_select_search::-webkit-scrollbar {
    width: 4px;
}

.options_select_search::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, .2);
    /* color of the tracking area */
}

.options_select_search::-webkit-scrollbar-thumb {
    background-color: #7C1338;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: none;
    /* creates padding around scroll thumb */
}
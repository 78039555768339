@font-face {
    font-family: 'OpenSansRegular';
    src: url('../../../assets/fonts/Open_Sans/static/OpenSans-Regular.ttf');
}

.label_component label {
    font-family: 'OpenSansRegular';
    font-size: 14px;
    line-height: 22px;
    font-style: normal;
}
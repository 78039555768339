@font-face {
    font-family: 'OpenSansRegular';
    src: local('OpenSansRegular'), url('../../../assets/fonts/Open_Sans/static/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansBold';
    src: local('OpenSansBold'), url('../../../assets/fonts/Open_Sans/static/OpenSans-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.input {
    font-family: 'OpenSansRegular'
}


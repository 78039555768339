@font-face {
    font-family: 'OpenSansSemibold';
    src: local('OpenSansSemibold'), url('../../../assets/fonts/Open_Sans/static/OpenSans-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.check label {
    font-family: 'OpenSansSemibold';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
}
@font-face {
    font-family: 'OpenSansRegular';
    src: local('OpenSansRegular'), url('../../assets/fonts/Open_Sans/static/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

* {
    font-family: 'OpenSansRegular'
}

.nav-element:hover .nav-line {
    background-color: #DE6180;
    transition-duration: 150ms
}
@font-face {
    font-family: 'OpenSansSemiBold';
    src: url('../../assets/fonts/Open_Sans/static/OpenSans-SemiBold.ttf');
}

@font-face {
    font-family: 'OpenSansBold';
    src: url('../../assets/fonts/Open_Sans/static/OpenSans-Bold.ttf');
}
@font-face {
    font-family: 'OpenSansRegular';
    src: url('../../assets/fonts/Open_Sans/static/OpenSans-Regular.ttf');
}

.row_selector_branch .label_component label {
    font-family: 'OpenSansSemiBold';
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
}

#selector_section h3 {
    font-family: 'OpenSansSemiBold';
    font-size: 32px;
    line-height: 40px
}

#selector_section input::placeholder {
    color: #282828;
    font-size: 16px;
    line-height: 24px;
} 
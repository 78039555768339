@keyframes scrollHorizontalFade {
    0% {
        transform: translateX(0%);
        opacity: 1;
    }

    100% {
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes scrollHorizontalFadeOut {
    0% {
        transform: translateX(0%);
        opacity: 0;
    }

    100% {
        transform: translateX(-100%);
        opacity: 1;
    }
}

.carousel .carousel_item.scroll-horizontal-fade.hidden {
    overflow: hidden;
    animation: scrollHorizontalFadeOut 0.7s ease-in normal;
}

.carousel .carousel_item.scroll-horizontal-fade {
    overflow: hidden;
    animation: scrollHorizontalFade 0.7s ease-in normal;
}
.modal_benefits {
    clip-path: border-box;
}

.modal_benefits::-webkit-scrollbar {
    width: 4px;
}

.modal_benefits::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, .2);
    /* color of the tracking area */
}

.modal_benefits::-webkit-scrollbar-thumb {
    background-color: #7C1338;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: none;
    /* creates padding around scroll thumb */
}
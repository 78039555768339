@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');

.modal .overflow-auto {
    clip-path: border-box;
}

.modal .overflow-auto::-webkit-scrollbar {
    width: 4px;
}

.modal .overflow-auto::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, .2); /* color of the tracking area */
}

.modal .overflow-auto::-webkit-scrollbar-thumb {
    background-color: #7C1338; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: none; /* creates padding around scroll thumb */
}